import base64url from 'base64url'
import sha512 from 'js-sha512'

export const hash = value => base64url(sha512(value))

export const dateFormat = value => {
    return value !== undefined? new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    }).format(value) : undefined
}